
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { defineComponent, computed, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import moment from "moment";

export default defineComponent({
  props: ["isLoading"],
  components: {
    Datatable,
  },

  setup() {
    const route = useRoute();
    const tableHeader = ref([
      {
        name: "No.",
        key: "no",
        sortable: false,
        align: "center",
      },
      {
        name: "Username",
        key: "users_id",
        sortable: false,
        align: "center",
      },
      {
        name: "Rider name",
        key: "users_name",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "Join date",
        key: "create_at",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
      {
        name: "Balance",
        key: "users_balance",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
      {
        name: "Area",
        key: "users_district",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "E-mail",
        key: "users_email",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
      {
        name: "Tel.",
        key: "users_phone",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
      {
        name: "Status",
        key: "users_status",
        sortingField: "status.label",
        sortable: false,
        align: "center",
      },
      {
        name: "Actions",
        key: "actions",
        sortingField: "status.label",
        sortable: false,
      },
    ]);
    const searchConfig = computed(() => {
      return store.state.RiderModule.filterRiderListForm;
    });

    const store = useStore();
    const riderList = computed(() => {
      return store.getters["RiderModule/getRiderList"].filter((val) => {
        return (
          ((searchConfig.value.users_status != -1 &&
            val.users_status == searchConfig.value.users_status) ||
            searchConfig.value.users_status == -1) &&
          (val.users_id
            .toLowerCase()
            .includes(searchConfig.value.search_text.toLowerCase()) ||
            val.users_name
              .toLowerCase()
              .includes(searchConfig.value.search_text.toLowerCase()) ||
            val.users_email
              .toLowerCase()
              .includes(searchConfig.value.search_text.toLowerCase()) ||
            val.users_phone
              .toLowerCase()
              .includes(searchConfig.value.search_text.toLowerCase()) ||
            val.users_district.some((district) => {
              return (
                district.district_name_th
                  .toLowerCase()
                  .includes(searchConfig.value.search_text.toLowerCase()) ||
                district.district_name_en
                  .toLowerCase()
                  .includes(searchConfig.value.search_text.toLowerCase())
              );
            }))
        );
      });
    });

    return {
      moment,
      tableHeader,
      riderList,
      searchConfig,
      route,
    };
  },
});
